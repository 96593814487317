@import url("https://fonts.googleapis.com/css2?family=Kodchasan:wght@400;500&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;500&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Arimo:wght@400;500&display=swap");

@import "../node_modules/ag-grid-community/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/styles/ag-theme-alpine.css";

@font-face {
  font-family: Avant;
  src: local("Pacifico Regular"), url("assets/fonts/AvantGarde.woff");
  font-display: swap;
}

body {
  margin: 0;
  background-color: #f5f6f8;
  overflow-x: hidden;
  touch-action: pan-y !important;
}

*:focus {
  outline: none;
}

:root {
  /* --primary-background: #2a4056; */
  /* --header-background: #2f4e6d!important; #355574 */
  --primary-background: #2f4e6d;
  --header-background: #2f4e6d;
  --btnColor: #0b5aa6;
}

h1,
h2,
h3 {
  font-family: Avant;
}

a {
  color: inherit !important;
}

.btn {
  font-family: Avant;
  color: #356387;
}

.btn-primary {
  background-color: #305d8c;
}

.btn-outline-primary {
  border: 1px solid var(--primary-background);
}

.btn-outline-primary:hover {
  background-color: var(--primary-background);
}

.btn-outline-secondary {
  color: #6c757d;
  border: 1px solid #e1e5eb !important;
  background-color: #fff;
  line-height: 1rem;
}

.btn-outline-secondary:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(129, 142, 163, 0.2),
    0 0.0625rem 0.125rem rgba(129, 142, 163, 0.3);
  background-color: #fff;
  color: inherit;
  border: 1px solid #fff;
}

.btn-outline-secondary:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.15),
    0 3px 15px rgba(255, 255, 255, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1) !important;
}

.btn-outline-secondary:active {
  color: #fff;
}

.btn:disabled {
  border-color: #d0d0d0;
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active,
.btn:focus-visible {
  color: var(--primary-background) !important;
  background-color: #ebebeb !important;
}

.btnDatePicker {
  max-height: 38px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}

.flex-1 {
  flex: 1;
}

.text-left {
  text-align: left;
}

.pl-3 {
  padding-left: 1rem;
}

.cursor-pointer {
  cursor: pointer;
}

.ag-header-container,
.ag-header-row {
  min-width: 100% !important;
}

.ag-row:hover {
  cursor: pointer;
}

.ag-header, .ag-header-row, .ag-header-container, .ag-header-cell {
  height: 40px!important;
  min-height: 40px!important;
  max-height: 40px!important;
}

.ag-header-row {
  background-color: var(--primary-background);
  font-family: "Avant";
  font-weight: 500 !important;
  letter-spacing: 1px;
  font-size: 14px;
  /* height: 40px !important; */
}

.ag-header-cell {
  color: white;
}

/* .ag-row,
.ag-cell {
  height: 38px !important;
} */

.ag-cell:not(:last-child) {
  display: flex;
  align-items: center;
}

.ag-cell:not(:last-child) {
  border-right: 1px solid #ededed !important;
}

.ag-header-cell-label,
.ag-row .ag-cell:not(:first-child) {
  display: flex;
  justify-content: center;
}

.ag-ltr .ag-cell-focus {
  border: 0px !important;
}

.cardHover:hover .ag-root-wrapper {
  border: 1px solid #d9d9d9 !important;
  transition-duration: 0.2s;
}

.cardHover:hover .lastTransactionTable .ag-root-wrapper {
  border: 0px !important;
}

.lastTransactionTable .ag-root-wrapper .editedTransaction span span {
  width: 13px;
  height: 13px;
  font-size: 10px;
}

.bgAliceOnHover:hover,
.bgAliceOnHover:hover > * {
  background-color: aliceblue !important;
}

.lowSumBackground {
  background-color: #ff000047;
}

.manat {
  font-family: "Arimo", sans-serif;
  font-size: 16px !important;
}

.fw-500 {
  font-weight: 500;
}

.fs-14 {
  font-size: 14px !important;
}

.cdk-overlay-pane {
  max-width: 100vw !important;
}

.bg-toastr,
.mat-calendar-body-selected {
  background-color: var(--primary-background) !important;
}

.bg-toastr a {
  padding: 5px 50px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.285);
  width: 100% !important;
  margin-top: -20px;
}

.customToast {
  background-color: #2a445f;
  border-left: 5px solid #00000050;
  padding: 0.75rem 1rem 0.75rem 3.2rem !important;
  max-width: 250px;
}

.customToast .toast-progress {
  background-color: #cecece !important;
}

.userName {
  margin-block: 1px -18px;
  font-family: "Mulish";
  font-size: 14px;
  display: flex;
  justify-content: end;
  align-items: center;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.userName span {
  font-size: 14px;
  height: 10px;
  margin-right: 2px;
}

.editedTransaction span {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

/* EDIT SYMBOL (R) */
.editedTransaction span span {
  font-style: italic;
  font-size: 11px;
  color: #7b7b7b;
  font-family: "Avant";
  border: 1px solid;
  border-radius: 100%;
  width: 17px;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 2px;
}

/* .topBar {
	background-color: #ededed69;
    background: radial-gradient(circle, rgba(247,247,247,1) 0%, rgba(251,251,251,1) 52%, rgba(247,247,247,1) 100%);
    padding: 0.35rem 0px;
    color: #335575;
    border-bottom: 1px solid lightgray!important;
    box-shadow: 1px 0px 2px lightgrey;
}

.topBar .btn, .topBar .mat-icon {
	background-color: white;
    color: #335575;
    border: 1px solid lightgray;
}

.topBar h3 {
	margin-bottom: 0px;
	font-family: 'Asar';
    font-size: 32px;
} */

/* TOPBAR BUTTONS */
.navbar {
  background-color: #ededed69;
  background: radial-gradient(
    circle,
    rgba(247, 247, 247, 1) 0%,
    rgba(251, 251, 251, 1) 52%,
    rgba(247, 247, 247, 1) 100%
  );
  color: #335575;
  border-bottom: 1px solid lightgray !important;
  box-shadow: 1px 0px 2px lightgrey;
  letter-spacing: 1px;
  padding: 0.3rem 0;
  position: fixed;
  top: 0;
  width: calc(100% - 60px);
  z-index: 999;
}

.navbar-toggler {
  margin-left: auto;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.navbar h3 {
  font-size: 1.4rem;
}

.topBar .form-group {
  margin-bottom: 5px;
}

.mt-6 {
  margin-top: 4rem !important;
}

.text-muted {
  color: rgb(119 148 175 / 75%) !important;
}

.mdc-button__label {
  display: flex;
  flex: 1;
}

.mat-mdc-form-field-subscript-wrapper,
.mdc-line-ripple {
  display: none;
}

.mat-mdc-text-field-wrapper {
  font-family: Avant !important;
  display: flex !important;
  align-items: center !important;
  background: white !important;
  border: 1px solid #ececec !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.mdc-text-field {
  padding-left: 0px 20px !important;
}

.mdc-text-field__input {
  color: var(--btnColor) !important;
}

.mat-button-wrapper {
  letter-spacing: 1px;
}

.mat-mdc-form-field-infix {
  width: 185px !important;
}

.mat-mdc-form-field-icon-suffix {
  padding-left: 0px !important;
}

.mat-mdc-button-base {
  max-height: 43px !important;
  min-width: max-content !important;
  color: var(--btnColor) !important;
  background-color: inherit !important;
}

.mat-form-field-wrapper {
  max-height: 63px !important;
  padding-bottom: 0px !important;
}

.mat-mdc-form-field {
  max-height: 38px !important;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: white;
  border: 1px solid #ececec;
  align-items: flex-start !important;
  border-radius: 0 4px 4px 0 !important;
  padding: 0 15px 0 20px !important;
  font-family: "Avant";
  max-height: 38px;
  line-height: 1;
}

.mat-mdc-icon-button {
  width: var(--mdc-icon-button-state-layer-size, 48px)!important;
  height: var(--mdc-icon-button-state-layer-size, 48px)!important;
  display: flex !important;
  align-items: center !important;
  color: var(--btnColor) !important;
}

.mat-form-field-underline {
  width: 0% !important;
}

.mat-form-field-underline {
  bottom: 0px !important;
  position: relative !important;
}

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0 0 6px 0 !important;
  border-top: 10px solid transparent !important;
  width: 180px !important;
}

.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgb(209 209 209);
}

.mdc-evolution-chip-set__chips {
  justify-content: end;
}

.mat-mdc-standard-chip .mdc-evolution-chip__text-label {
  font-family: Avant !important;
}

.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: var(--primary-background) !important;
  padding: 5px;
}

.mat-sidenav-content {
  /* background: #f4f4f4; */
  background: linear-gradient(
    135deg,
    rgba(25, 65, 103, 0.05) 0%,
    rgba(247, 247, 247, 0.5) 25%,
    rgba(25, 65, 103, 0.04555325548188027) 50%,
    rgba(247, 247, 247, 0.5) 75%,
    rgba(25, 65, 103, 0.05) 100%
  );
}

.sidenav-content {
  transition-duration: 0.3s;
}

.materialsTable {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  gap: 0.7rem;
}
.materialsTableWithDel {
  grid-template-columns:
    minmax(200px, 2fr) repeat(auto-fill, minmax(150px, 1fr))
    45px;
}

.materialsTableHeader {
  color: #2c4c66;
  font-weight: 500;
  height: 20px;
}

.materialsTable div:first-child {
  grid-column: span 2;
}

.btnDelete {
  padding: 6px 10px 0px !important;
  border: 0px;
  box-shadow: 0px 0px 2px 1px #8f8f8f5e;
  background-color: white;
}

.btnDelete:disabled {
  color: black;
}

.context-menu {
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 150px;
}

.context-menu .filterItems button {
  border: 0px !important;
  box-shadow: none;
  border-radius: 0px !important;
}

.context-menu .filterItems button:not(:last-child) {
  border-bottom: 1px solid #ececec !important;
}

.mobileOnly {
  display: none;
}

.pcOnly {
  display: inherit;
}

.c-pointer {
  cursor: pointer;
}

@media screen and (max-width: 769px) {
  .pcOnly {
    display: none !important;
  }

  .mobileOnly {
    display: inline-block;
  }

  .gap1mobile {
    gap: 1;
  }

  .homeTitleContainer h3,
  .homeTitleContainer button {
    margin-left: auto;
  }

  .homeTitleContainer .lds-dual-ring {
    position: absolute;
    right: 30px;
    top: 55px;
    z-index: 999999;
  }

  .w-sm-100 {
    width: 100% !important;
  }

  .mt-smm-5 {
    margin-top: 0.5rem !important;
  }

  .mt-smm-6 {
    margin-top: 1rem !important;
  }

  .grid-span-2-mobile {
    grid-column: span 2 !important;
  }

  .gap-mobile {
    gap: 10px !important;
  }

  .topBar {
    height: 51px !important;
    padding-left: 55px;
    padding-bottom: 120px;
  }

  .navbar h3 {
    font-size: 1.3rem;
  }

  .navbar {
    width: 100%;
  }

  .btn-group,
  .btn-group-vertical {
    display: grid;
  }

  .toplamIstehsalContainer {
    justify-content: flex-start !important;
    margin-top: 5px !important;
  }

  .toplamIstehsal {
    font-size: 18px !important;
  }
  .mat-form-field-appearance-fill .mat-form-field-flex {
    border-radius: 4px !important;
    border: 1px solid #dddddd;
  }

  #targetTable {
    display: flex;
  }

  #targetTable div div {
    justify-content: center;
  }

  .mdc-evolution-chip-set__chips {
    justify-content: flex-start;
  }

  .mobileMaterialsList {
    grid-template-columns: 1fr 1fr !important;
  }

  .mobileMaterialsList div:first-child {
    grid-column: 1/2 !important;
  }

  .mobileMaterialsList .priceTotal {
    grid-column: 2 !important;
    grid-row: 1/2 !important;
  }

  .mobileMaterialsList .btnDeleteContainer {
    margin-left: auto;
    grid-column: 2;
  }
}

@media screen and (max-width: 992px) {
  .navbar-nav {
    gap: 0.5rem !important;
    margin-top: 0.5rem !important;
  }

  .navbar-nav li > :first-child {
    width: 100%;
  }

  .cdk-global-overlay-wrapper {
    pointer-events: auto;
  }

  .summaryElementContainer {
    flex-direction: column;
    position: relative;
  }

  .summaryElementContainer .manat {
    display: none;
  }
}

@media screen and (min-width: 769px) {
  .d-flex-mobile {
    display: flex !important;
  }
  .homeTitleContainer {
    justify-content: flex-start !important;
  }

  .btnTopBar {
    color: #0b5aa6;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-right: 0px !important;
    background-color: white !important;
  }

  .btnTopBar .material-icons {
    margin-right: 5px !important;
  }

  .sidenav-container .sidenav-content {
    margin-left: 60px;
  }

  mat-sidenav:not(.mat-drawer-opened) {
    transform: translate3d(0, 0, 0) !important;
    visibility: visible !important;
    width: 60px !important;
    overflow: hidden;
  }

  mat-sidenav:not(.mat-drawer-opened) .nav-text,
  mat-sidenav:not(.mat-drawer-opened) .reports,
  mat-sidenav:not(.mat-drawer-opened) .hideNavText {
    display: none !important;
  }

  mat-sidenav:not(.mat-drawer-opened) .syncDate {
    display: flex;
    justify-content: center;
    text-align: center;
    padding-left: 0px;
  }

  .mat-drawer-opened ~ .mat-drawer-content .navbar {
    width: 100%;
  }

  .sidenav-container .loginPage {
    transform: translate(0) !important;
    margin-left: 0 !important;
    width: 100% !important;
  }
}

.mat-drawer[style*="visibility: hidden"] {
  display: inherit !important;
}

input[type="search"]::-webkit-search-cancel-button {
  cursor: pointer;
}

.input-group-text {
  color: #0f4c7d;
  background-color: #f8f8f8;
  min-height: 38px;
  width: 51px;
  display: flex;
  justify-content: center;
}

.unselectable,
.topBar {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

label {
  font-weight: 500;
}

body,
button,
html,
div,
button:focus,
a:focus {
  outline: 0px auto rgba(0, 0, 0, 0);
  outline-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

/* SCROLLBAR */
::-webkit-scrollbar {
  width: 6px;
  background: transparent;
  display: none;
  transition: 0.3s ease;
}

::-webkit-scrollbar:hover {
  width: 8px;
}

.ag-theme-alpine ::-webkit-scrollbar,
.tableColumnsSelectorContainer ::-webkit-scrollbar {
  display: block;
}

.ag-root-wrapper {
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #27415b;
  border-radius: 5px;
  transition: 0.3s ease;
  min-height: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #21374e;
}

::-webkit-scrollbar:horizontal {
  display: none;
}

@media screen and (max-width: 769px) {
  ::-webkit-scrollbar {
    display: none;
  }
}

.toplam {
  color: #356387;
  font-family: "Avant";
  line-height: 34px;
  display: none;
}

/*TABLE*/
.loadingContainer {
  margin-top: -35px !important;
  background: white;
  z-index: 999;
}

.mat-elevation-z1 {
  overflow-x: auto;
}

.mat-mdc-table {
  border-radius: 5px !important;
  letter-spacing: 0.2px !important;
  overflow: inherit !important;
  background-color: white !important;
  font-family: "Mulish", sans-serif !important;
  font-size: 15px !important;
}

.mdc-data-table__cell {
  letter-spacing: 0.2px !important;
  overflow: inherit !important;
  background-color: white !important;
  font-family: "Mulish", sans-serif !important;
  font-size: 15px !important;
}

.sticky-table {
  height: 90vh;
  overflow: auto;
  margin-bottom: 100px;
}

tr.mat-mdc-row,
tr.mat-mdc-footer-row {
  height: 32px !important;
}

tfoot {
  border-top: 1px solid #dbdada;
  border-bottom: 1px solid #dbdada;
}

tr.mat-mdc-header-row {
  height: 40px !important;
  background: var(--header-background) !important;
  letter-spacing: 1.5px;
}

.mat-mdc-table-sticky-border-elem-top {
  background: var(--header-background) !important;
  font-weight: 400;
}

.mat-mdc-header-cell,
.mat-sort-header-arrow {
  color: white;
}

.mat-sort-header-arrow {
  display: none !important;
}

td.mat-mdc-cell {
  border-right: 1px solid #ececec !important;
  padding-inline: 10px !important;
  border: 1px solid #ececec !important;
  /* background-color: white; */
}

th.mat-mdc-header-cell {
  border-right: 1px solid #7d7d7d !important;
  font-size: 15px;
  font-family: "Avant";
}

td.mat-mdc-cell:not(:first-child),
th.mat-mdc-header-cell,
.mat-mdc-footer-cell:not(:first-child),
.mat-sort-header {
  text-align: center !important;
}

.mat-sort-header-container {
  justify-content: center !important;
}

.mat-sort-header-content {
  letter-spacing: 1.5px;
}

td.mat-mdc-footer-cell:last-of-type {
  padding-right: 0px !important;
}

tr.mat-mdc-footer-row td.mat-mdc-footer-cell {
  border-bottom: 0px !important;
}

th.mat-mdc-header-cell:first-of-type {
  padding-left: 0px !important;
}

.mat-column-delete {
  width: 75px;
}

.mat-mdc-standard-chip.mdc-evolution-chip--selected {
  background-color: var(--primary-background)!important;
}

@media (min-width: 700px) {
  .mat-column-details {
    word-wrap: break-word !important;
    white-space: unset !important;
    flex: 0 0 20% !important;
    width: 20% !important;
    overflow-wrap: break-word;
    word-wrap: break-word;

    word-break: break-word;

    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
}

/* END SCROLLBAR */

.mdc-tooltip__surface {
  background-color: #384d62 !important;
  font-family: "Avant" !important;
  font-size: 14px !important;
}

.customTooltip {
  padding: 8px;
  font-size: 12px;
}

.navToolTip {
  padding: 8px 10px 7px 10px !important;
  background-color: #384d62 !important ;
  margin-left: -8px !important;
  display: inline-flex;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  max-height: 45px;
  overflow-y: hidden;
}

.line-broken-tooltip {
  white-space: pre-line !important;
  padding: 6.5px;
  background-color: #384d62;
  letter-spacing: 1;
  border-radius: 6px !important;
}

.roundedTooltip {
  background-color: var(--primary-background) !important;
  border-radius: 6px !important;
  padding-block: 6.5px !important;
}

.roundedTooltip .mdc-tooltip__surface {
  background-color: var(--primary-background) !important;
}

.customMaterialIcon {
  font-size: 18px;
}

.clipText span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
  overflow: auto;
  /* pointer-events: auto; */
}

.mat-mdc-dialog-container {
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.mdc-dialog .mdc-dialog__content {
  padding: 0px !important;
  font-family: inherit !important;
  letter-spacing: inherit !important;
}

.mdc-dialog__title {
  display: flex !important;
  padding: 0 !important;
  margin: 0 !important;
}

.materialDialog .mat-mdc-dialog-container .mdc-dialog__surface {
  overflow: inherit !important;
}

.inputGroupButtons {
  padding: 0 !important;
  transition: 0.3s;
}

.inputGroupButtons a {
  padding: 6px 7px 1px 8px !important;
}

.inputGroupButtons:hover {
  box-shadow: inset 0px 0px 2px #2a2a2a;
}

.form-label {
  font-weight: 500;
  color: var(--primary-background) !important;
  font-family: Mulish;
  font-size: 15px;
  margin-bottom: 5px !important;
}

/* NG SELECT */
.ng-dropdown-panel {
  top: 100%;
  border-radius: 0px 0px 4px 4px;
  border-top-color: #e6e5e5;
  margin-top: -1px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgb(0 0 0 / 6%);
  left: 0;
  padding: 0;
}

.panelUp .ng-dropdown-panel {
  bottom: 100%;
  top: unset;
}

.ng-dropdown-panel .ng-dropdown-panel-items {
  display: block;
  height: auto;
  box-sizing: border-box;
  max-height: 200px !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  padding: 8px 10px;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.1rem;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option:hover,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: aliceblue;
}

input:placeholder-shown:required,
ng-select.ng-invalid {
  /* box-shadow: inset 0px 0px 0px 1px #2c4c66; */
  border: 1px dashed red;
}

.ng-select .ng-placeholder {
  color: #6c757d;
}

.ng-select .ng-has-value .ng-placeholder {
  display: none;
}

.ng-option-selected {
  z-index: 100;
  position: relative;
}

.ng-optgroup {
  color: darkslategray;
  font-family: "Avant";
  padding-left: 0.75rem;
  border-bottom: 1px solid lightgray;
  background-color: #f5f5f5;
  position: sticky;
  top: 0;
}

/* MODAL TOPBAR */
.mat-mdc-dialog-container {
  padding: 0px !important;
}

.ui-titlebar {
  display: flex;
  height: 36px;
  background: #2c4c66;
  user-select: none;
  cursor: move;
  padding: 0px;
}

.ui-titletext {
  width: auto;
  font-family: "Avant";
  color: #fff;
  text-indent: 10px;
  padding-left: 3px;
  letter-spacing: 1.1px;
  font-size: 1.05rem;
  display: flex;
  align-items: center;
  flex: 1;
  height: 100%;
  max-width: 75vw;
  overflow: hidden;
}

.ui-titlecontrols {
  max-width: 49px;
  margin-left: auto;
}

.ui-btn {
  margin: 0;
  width: 48px;
  height: 36px;
  border: 0;
  outline: 0;
  background: transparent;
}

.ui-btn:hover {
  background: rgba(255, 255, 255, 0.1);
}

.cursorWait,
.cursorWait * {
  cursor: wait !important;
}

.ui-btn.close:hover {
  background: #e81123;
}

.ui-btn.operations:hover,
.transactionNo:hover {
  background: #00000054;
}

.ui-btn svg path,
.ui-btn svg rect,
.ui-btn svg polygon {
  fill: #fff;
}

.ui-btn svg {
  width: 10px;
  height: 10px;
}

.close,
.operations {
  line-height: 0.5;
}

.transactionNo {
  font-size: 0.85rem;
  margin-left: auto;
  padding-inline: 5px 15px;
  height: 100%;
  line-height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f6f6f6;
}

/* .form-control:disabled, .ng-select-disabled {
  color: var(--header-background);
} */

/* END MODAL TOPBAR */

/* Bank GROUPS DIALOG */
.customSelect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 2px 10px !important;
  font-size: 17px !important;
}

.customSelect .ng-value-container {
  font-size: 16px;
  align-items: center;
  padding-left: 6px;
  font-family: "Mulish";
}
/* END Bank GROUPS DIALOG */

.nsm-content {
  padding: 0 !important;
}

.nsm-dialog-btn-close {
  display: none;
}

.mat-mdc-row:hover {
  background-color: #f5f5f5 !important;
}

.mat-mdc-row:hover .mdc-data-table__cell {
  background: rgba(247, 247, 247, 0.5) !important;
}

.sticky-top {
  z-index: 10 !important;
}

/* LOADING SPINNER */
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #003069;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.spinner-message {
  text-align: center;
}

.divider {
  width: 16px;
  height: 16px;
  background-color: #0088cc;
  border-radius: 100%;
}

.divider::before {
  content: "";
  position: absolute;
  height: 2px;
  width: 100px;
  background-color: #5f5f5f;
  top: 50%;
  left: 52%;
}

.divider::after {
  content: "";
  position: absolute;
  height: 2px;
  width: 100px;
  background-color: #5f5f5f;
  top: 50%;
  right: 52%;
}

.lds-dual-ring {
  display: inline-block;
  width: 36px;
  height: 36px;
  margin-top: -3px;
  margin-left: 3px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 30px;
  height: 30px;
  margin: 4px 13px;
  border-radius: 50%;
  border: 3px solid var(--header-background);
  border-color: var(--header-background) transparent var(--header-background)
    transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

button .lds-dual-ring {
  width: 24px;
  height: 24px;
}

button .lds-dual-ring:after {
  width: 24px;
  height: 24px;
  margin: 0px 10px;
  border: 2px solid var(--header-background);
  border-color: #2f4e6d transparent #2f4e6d transparent;
  border-color: var(--header-background) transparent var(--header-background)
    transparent;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.entryType {
  display: flex;
  justify-content: center;
  line-height: 1;
}

.entryType span {
  color: #fff;
  background: var(--primary-background);
  border-radius: 100%;
  font-size: 14px;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stockOut span {
  background-color: #6d2f51;
}

.stockTransfer span {
  background-color: #2f6c6d;
}

/* SUMMARY */
.summaryContainer {
  display: flex;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  background: white;
  height: 100%;
  align-items: center;
  color: var(--btnColor);
  min-height: 37.5px;
  flex-wrap: wrap;
}

.summaryContainer > * {
  flex: 1;
  text-align: center;
  font-family: "Mulish";
}

.summaryContainer > *:not(:last-child) {
  border-right: 1px solid #bfbfbf;
}

.summaryElementContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}

.summaryType {
  color: black;
  font-size: 13px;
  line-height: 1;
}

.summaryType {
  line-height: 1;
}

.summaryElementContainer .manat {
  font-size: 13px !important;
}
/* END SUMMARY */

.tableRing {
  margin-bottom: 0px !important;
  margin-left: 50px !important;
}

.tableRing,
.tableRing::after {
  width: 20px !important;
  height: 20px !important;
}

.tableDataLoading {
  background-color: aliceblue !important;
  transition: background-color 1s ease;
}

.minusValueRedColor {
  color: #b60000;
}

.minWordSpace {
  word-spacing: -1px !important;
  white-space: nowrap;
}

.noWrap {
  white-space: nowrap;
}

.cardsContainer {
  display: grid;
  column-gap: 1em;
  grid-template-columns: repeat(auto-fit, minmax(min(170px, 45%), 1fr));
  grid-template-rows: auto;
  grid-auto-rows: 0px;
  position: relative;
  overflow: hidden;
}

.gridAutoRows {
  grid-auto-rows: auto;
}

.cards {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 8px 2px #e4e4e426;
  border: 1px solid #ececec;
  border-radius: 8px;
  background: white;
  padding: 0.7em 0.85em 0.75em 0.85em;
  justify-content: space-between;
  flex: 1;
  width: auto;
  max-width: inherit;
  min-height: 100px;
  margin-bottom: 1rem;
}

.cards h4 {
  color: #356387;
  font-size: 18px;
  font-weight: 400;
  font-family: "Mulish";
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mleft-0 {
  margin-left: 0 !important;
}

.cardHover {
  transition-duration: 0.2s !important;
}

.cardHover:hover {
  box-shadow: 0px 0px 8px 4px #a6a6a633 !important;
}

body,
button,
html,
div,
button:focus,
a:focus {
  outline: 0px rgba(0, 0, 0, 0) !important;
}

.ml-auto {
  margin-left: auto;
}

.mt-40 {
  margin-top: -40px;
}

.mt-10 {
  margin-top: -10px;
}

.padding-block-5 {
  padding-block: 6px !important;
}

input,
.ng-select,
textarea {
  transition-duration: 0.2s;
}

input:not(:disabled):hover,
ng-select:not(:disabled):hover,
textarea:not(:disabled):hover {
  box-shadow: 0px 0px 8px 4px #a6a6a633 !important;
}
